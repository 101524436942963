window.addEventListener('DOMContentLoaded', run);

const loader = document.querySelector('.loader');
const submitButton = document.querySelector('.send-button');
const successMessage = document.querySelector('.success-message');
const errorMessage = document.querySelector('.error-message');
const innerForm = document.querySelector('.inner-contact-form');

function run(e) {
    initFormSecret();
    handleFormSubmit({
        selector: '.contact-form',
        uri: 'https://contact.zaia.dev/api/contact',
        onLoading: state => {
            console.log(state ? 'started loading' : 'stopped loading');
            loader.classList.toggle('show');
            submitButton.classList.toggle('hidden');
        },
        onSuccess: data => {
            console.log('form success', data);
            successMessage.classList.toggle('hidden');
            innerForm.classList.toggle('hidden');
        },
        onFailure: data => {
            console.log('form failure', data);
            errorMessage.classList.toggle('hidden');
            innerForm.classList.toggle('hidden');
        },
        onError: error => {
            console.log('form error', error);
            errorMessage.classList.toggle('hidden');
            innerForm.classList.toggle('hidden');
        }
    });
}

function handleFormSubmit(params) {
    if (typeof params.onSuccess !== 'function') {
        params.onSuccess = () => '';
    }

    if (typeof params.onFailure !== 'function') {
        params.onFailure = () => '';
    }

    if (typeof params.onError !== 'function') {
        params.onError = () => '';
    }

    if (typeof params.onLoading !== 'function') {
        params.onLoading = () => '';
    }

    document.querySelector(params.selector).addEventListener('submit', async e => {
        e.preventDefault();

        const formData = new FormData();
        const date = new Date();
        const dateSum =
            date.getUTCFullYear() + date.getUTCMonth() + 1 + date.getUTCDate() + date.getUTCHours();
        const answer = !(dateSum % 2) ? 'yes' : 'no';
        const xyz = Object(Object(document.querySelector('.xyz')).dataset).xyz;

        formData.append('answer', answer);
        formData.append('xyz', xyz);

        for (let i = 0, ii = e.target.elements.length; i < ii; i++) {
            const element = e.target.elements[i];

            if (element.type === 'submit') {
                continue;
            }

            if (element.type === 'file') {
                formData.append(element.name || 'file', element.files[0]);
            } else {
                formData.append(element.name || element.id || element.tagName + i, element.value);
            }
        }

        params.onLoading.call(this, true);

        try {
            const response = await fetch(params.uri, {
                method: 'POST',
                body: formData,
                referrerPolicy: 'strict-origin-when-cross-origin'
            });

            const data = await response.json();

            if (data.success) {
                params.onSuccess.call(this, data);
            } else {
                params.onFailure.call(this, data);
            }
        } catch (error) {
            params.onError.call(this, error);
        }

        params.onLoading.call(this, false);
    });
}

function initFormSecret() {
    setTimeout(() => {
        let parent = document.createElement('div');
        let i = 0;

        document.body.appendChild(parent);

        while (i < 5) {
            const child = document.createElement('div');
            parent.appendChild(child);
            parent = child;
            i++;
        }

        parent.classList.add('xyz');
        parent.dataset.xyz =
            rand(1, 'zrtef') +
            rand(9, '13abcdefgzxcv_') +
            rand(2, '0123456789') +
            rand(5, 'asdfghjklmnb');
    }, 2000);
}

function rand(length, characters) {
    let result = '';
    const len = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * len));
    }
    return result;
}