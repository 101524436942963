import '../css/style.scss';
import '../css/cookieconsent.scss';
import '../css/contact-form.scss';
import './func/vertical-slider';
import './func/contact-form';

const cookiesConsent = require('./func/cookies-consent');
const currentYear = require('./func/current-year');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {}
