require('vanilla-cookieconsent/dist/cookieconsent');
require('vanilla-cookieconsent/dist/cookieconsent.css');
const cc = initCookieConsent();

cc.run({
    current_lang: 'en',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function (user_preferences, cookie) {
        // callback triggered only once
        console.log('cookies -> onFirstAction');
    },

    onAccept: function (cookie) {
        // ...
        console.log('cookies -> onAccept');
    },

    onChange: function (cookie, changed_preferences) {
        // ...
        console.log('cookies -> onChange');
    },

    gui_options: {
        consent_modal: {
            layout: 'box', // box/cloud/bar
            position: 'bottom left', // bottom/middle/top + left/right/center
            transition: 'slide' // zoom/slide
        }
    },

    languages: {
        en: {
            consent_modal: {
                title:
                '<div class="bar-title"><div class="bar-title-icon"> <img src="/image/icon-cookie.png"></div>Cookie Policy</div>',
                description:
                    'We use cookies and other tracking technology to enhance your experience with our website and to make your visit to our website more enjoyable. Read our  <a href="/privacy-policy.html">cookie policy</a> for full information.',
                primary_btn: {
                    text: 'Accept',
                    role: 'accept_all' // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Manage',
                    role: 'settings' // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: '<div class="setting-title">Manage your data</div><div class="setting-info">We would like to be in control of the information that different categories of cookies collect. Below you can manage your cookie preferences.</div>',
                save_settings_btn: 'Save Settings',
                accept_all_btn: 'Accept all',
                close_btn_label: 'Close',
                blocks: [
                    {
                        title: 'Essential cookies <span class="setting-m-break">(required)</span>',
                        description:
                            'Essential cookies are vital to the functionality of our website and cannot be switched off in our system. They can only be disabled by changing your browser preferences and can lead to reduced functionality of our website.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    },
                    {
                        title: 'Analytical',
                        description:
                            'These cookies allow us to collect information on the use of the website by users (number of visitors, pages viewed, time spent on the website, etc.) in order to perform statistical analyses, on an aggregated basis, on the number of users and how users browse our website. <ul><li><a href="https://tools.google.com/dlpage/gaoptout?hl=gb" target="_blank">Google Analytics</a></li></ul>',
                        toggle: {
                            value: 'analytics-ga', // your cookie category
                            enabled: false,
                            readonly: false
                        }
                    },
                    {
                        description:
                            '<div class="settings-additional-info"><span><a href="/privacy-policy.html">Privacy Policy</a></span><span><a href="/terms-of-use.html">Terms & Conditions</a></span></div>'
                    }
                ]
            }
        }
    }
});
