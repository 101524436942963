function slider() {
    const slider = document.getElementById('slider');
    const sliderItems = document.querySelectorAll('#slider ul li');
    const prevBtn = document.getElementById('prevBtn');
    const nextBtn = document.getElementById('nextBtn');
    let currentSlide = 0;

    function showSlide(index) {
        if (index >= 0 && index < sliderItems.length) {
            let slideHeight = slider.clientHeight;
            slider.querySelector('ul').style.transform =
                'translateY(' + -index * slideHeight + 'px)';
            currentSlide = index;
            updateActiveButton();
        }
    }

    function scrollToNextSlide(e) {
        if (e.deltaY > 0 && currentSlide < sliderItems.length - 1) {
            showSlide(currentSlide + 1);
        } else if (e.deltaY < 0 && currentSlide > 0) {
            showSlide(currentSlide - 1);
        }
    }

    function prevSlide() {
        if (currentSlide > 0) {
            showSlide(currentSlide - 1);
        }
    }

    function nextSlide() {
        if (currentSlide < sliderItems.length - 1) {
            showSlide(currentSlide + 1);
        }
    }

    function updateActiveButton() {
        let buttons = document.querySelectorAll('.slider-btn');
        buttons.forEach(function (btn, index) {
            if (index === currentSlide) {
                btn.classList.add('active');
            } else {
                btn.classList.remove('active');
            }
        });
    }

    slider.addEventListener('wheel', scrollToNextSlide, { passive: true });
    prevBtn.addEventListener('click', prevSlide);
    nextBtn.addEventListener('click', nextSlide);
}

slider();